import { useTheme } from "../../../../hooks/theme";

export const OnlinePasswordIcon = ({ size, color, background, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="94" height="94" rx="47" stroke="#EBEBEB" stroke-width="2" stroke-dasharray="8 8"/>
    <rect x="20" y="20" width="56" height="56" rx="28" fill={colorsTheme.secondary.light} opacity='0.7'/>
    <path d="M58 46V43.2C58 42.0799 58 41.5198 57.782 41.092C57.5903 40.7157 57.2843 40.4097 56.908 40.218C56.4802 40 55.9201 40 54.8 40H41.2C40.0799 40 39.5198 40 39.092 40.218C38.7157 40.4097 38.4097 40.7157 38.218 41.092C38 41.5198 38 42.0799 38 43.2V46.8C38 47.9201 38 48.4802 38.218 48.908C38.4097 49.2843 38.7157 49.5903 39.092 49.782C39.5198 50 40.0799 50 41.2 50H47M48 45H48.005M53 45H53.005M43 45H43.005M55.25 52V50.25C55.25 49.2835 54.4665 48.5 53.5 48.5C52.5335 48.5 51.75 49.2835 51.75 50.25V52M48.25 45C48.25 45.1381 48.1381 45.25 48 45.25C47.8619 45.25 47.75 45.1381 47.75 45C47.75 44.8619 47.8619 44.75 48 44.75C48.1381 44.75 48.25 44.8619 48.25 45ZM53.25 45C53.25 45.1381 53.1381 45.25 53 45.25C52.8619 45.25 52.75 45.1381 52.75 45C52.75 44.8619 52.8619 44.75 53 44.75C53.1381 44.75 53.25 44.8619 53.25 45ZM43.25 45C43.25 45.1381 43.1381 45.25 43 45.25C42.8619 45.25 42.75 45.1381 42.75 45C42.75 44.8619 42.8619 44.75 43 44.75C43.1381 44.75 43.25 44.8619 43.25 45ZM51.6 56H55.4C55.9601 56 56.2401 56 56.454 55.891C56.6422 55.7951 56.7951 55.6422 56.891 55.454C57 55.2401 57 54.9601 57 54.4V53.6C57 53.0399 57 52.7599 56.891 52.546C56.7951 52.3578 56.6422 52.2049 56.454 52.109C56.2401 52 55.9601 52 55.4 52H51.6C51.0399 52 50.7599 52 50.546 52.109C50.3578 52.2049 50.2049 52.3578 50.109 52.546C50 52.7599 50 53.0399 50 53.6V54.4C50 54.9601 50 55.2401 50.109 55.454C50.2049 55.6422 50.3578 55.7951 50.546 55.891C50.7599 56 51.0399 56 51.6 56Z" stroke="#5B5B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
}

export const OfflinePasswordIcon = ({ size, color, background, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="94" height="94" rx="47" stroke="#EBEBEB" stroke-width="2" stroke-dasharray="8 8"/>
    <rect x="20" y="20" width="56" height="56" rx="28" fill={colorsTheme.secondary.light} opacity='0.7'/>
    <path d="M43 47V44C43 41.2386 45.2386 39 48 39C50.419 39 52.4367 40.7178 52.9 43M43.8 57H52.2C53.8802 57 54.7202 57 55.362 56.673C55.9265 56.3854 56.3854 55.9265 56.673 55.362C57 54.7202 57 53.8802 57 52.2V51.8C57 50.1198 57 49.2798 56.673 48.638C56.3854 48.0735 55.9265 47.6146 55.362 47.327C54.7202 47 53.8802 47 52.2 47H43.8C42.1198 47 41.2798 47 40.638 47.327C40.0735 47.6146 39.6146 48.0735 39.327 48.638C39 49.2798 39 50.1198 39 51.8V52.2C39 53.8802 39 54.7202 39.327 55.362C39.6146 55.9265 40.0735 56.3854 40.638 56.673C41.2798 57 42.1198 57 43.8 57Z" stroke="#5B5B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export const NoPasswordIcon = ({ size, color, background, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="94" height="94" rx="47" stroke="#EBEBEB" stroke-width="2" stroke-dasharray="8 8"/>
    <rect x="20" y="20" width="56" height="56" rx="28" fill={colorsTheme.secondary.light} opacity='0.7'/>
    <path d="M53 44.9999C53 44.4881 52.8047 43.9763 52.4142 43.5858C52.0237 43.1953 51.5118 43 51 43M51 51C54.3137 51 57 48.3137 57 45C57 41.6863 54.3137 39 51 39C47.6863 39 45 41.6863 45 45C45 45.2737 45.0183 45.5431 45.0538 45.807C45.1122 46.2412 45.1414 46.4583 45.1217 46.5956C45.1013 46.7387 45.0752 46.8157 45.0047 46.9419C44.937 47.063 44.8177 47.1823 44.5791 47.4209L39.4686 52.5314C39.2957 52.7043 39.2092 52.7908 39.1474 52.8917C39.0925 52.9812 39.0521 53.0787 39.0276 53.1808C39 53.2959 39 53.4182 39 53.6627V55.4C39 55.9601 39 56.2401 39.109 56.454C39.2049 56.6422 39.3578 56.7951 39.546 56.891C39.7599 57 40.0399 57 40.6 57H43V55H45V53H47L48.5791 51.4209C48.8177 51.1823 48.937 51.063 49.0581 50.9953C49.1843 50.9248 49.2613 50.8987 49.4044 50.8783C49.5417 50.8586 49.7588 50.8878 50.193 50.9462C50.4569 50.9817 50.7263 51 51 51Z" stroke="#5B5B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}
