import styled, { css } from "styled-components";
import { Line } from "../../../style";
import { IThemeProps, themeTypes } from "../../../interfaces";

interface IContainerProps extends IThemeProps {
    disabled?: boolean;
    background?: string;
}

export const ContainerIcon = styled(Line) <IContainerProps>`
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 14px;
    min-width: 38px;
    min-height: 38px;
    max-width: 38px;
    max-height: 38px;
    border-radius: 4px;
    background: ${props => props.background};
    ${props => props.disabled && css`
        opacity: 0.7;
        cursor: default;
    `}
`

export const ContainerTooltip = styled.div<IContainerProps>`
    ${props => props.disabled && css`
        span:nth-child(2) {
            display: none;
        }
    `}
`