import React from "react";
import { GlobalProvider } from "./global";
import { ApiProvider } from "./api";
import { ThemeProvider } from "./theme";

const AppProvider: React.FC = ({ children }) => {
  return (
    <GlobalProvider>
      <ApiProvider>
        <ThemeProvider>
          {children}
        </ThemeProvider>
      </ApiProvider>
    </GlobalProvider>
  );
};

export default AppProvider;
