import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { useTheme } from "../../hooks/theme";

interface IMyCircularProgress {
  color?: string;
  size?: number;
}

const Container = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    stroke: ${(props) => props.color} !important;
  }
`;

export const MyCircularProgress = ({ color, size }: IMyCircularProgress) => {
  const { colorsTheme } = useTheme();

  return (
    <Container color={color || colorsTheme.secondary.light}>
      <CircularProgress
        variant="indeterminate"
        style={{
          color: color,
          fill: color,
          stroke: color
        }}
        size={size}
      />
    </Container>
  );
};
