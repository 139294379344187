import { useTheme } from "../../../../hooks/theme";

export const SelfieIcon = ({ size, color, ...props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        stroke="#AEAEAE"
        strokeLinecap="round"
        strokeWidth={2.5}
        d="M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6m32 0c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C30.96 34 29.84 34 27.6 34m0-32c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C34 5.04 34 6.16 34 8.4M8.4 2c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C2 5.04 2 6.16 2 8.4M18 17.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 0c4.413 0 8 3.577 8 7.99a.01.01 0 0 1-.01.01m-7.99-8c-4.413 0-8 3.577-8 7.99 0 .005.005.01.01.01"
      />
    </svg>
  );
}

export const SelfieIcon2 = ({ size, color, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <path
        stroke="#3A3A3A"
        strokeLinecap="round"
        strokeWidth={2.5}
        d="M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6m32 0c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C30.96 34 29.84 34 27.6 34m0-32c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C34 5.04 34 6.16 34 8.4M8.4 2c-2.24 0-3.36 0-4.216.436a4 4 0 0 0-1.748 1.748C2 5.04 2 6.16 2 8.4"
      />
      <path
        stroke={colorsTheme.secondary.light}
        strokeLinecap="round"
        strokeWidth={2.5}
        d="M18 17.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 0c4.413 0 8 3.577 8 7.99a.01.01 0 0 1-.01.01m-7.99-8c-4.413 0-8 3.577-8 7.99 0 .005.005.01.01.01"
      />
    </svg>
  );
}

export const InstructSelfieIcon = ({ size, color, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg width="156" height="168" viewBox="0 0 156 168" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M105 150.133C134.208 139.196 155 111.026 155 78C155 35.4741 120.526 1 78 1C35.4741 1 1 35.4741 1 78C1 111.026 21.7919 139.196 51 150.133" stroke="#EBEBEB" stroke-width="2" stroke-linecap="round" stroke-dasharray="8 8" />
      <mask id="mask0_2022_623" type="alpha" maskUnits="userSpaceOnUse" x="20" y="18" width="117" height="117">
        <circle cx="78.5294" cy="76.5294" r="58.0294" fill="#00FE79" />
      </mask>
      <g mask="url(#mask0_2022_623)">
        <circle cx="78.2725" cy="76.2725" r="77.3272" fill={colorsTheme.secondary.light} opacity='0.8'/>
        <path d="M64.7094 85.4448C64.7094 85.4448 67.6792 93.0021 66.8661 98.4025C66.0531 103.803 63.0832 106.781 56.5958 110.017C50.1084 113.252 39.8552 117.848 38.7682 121.091C37.6813 124.335 38.7682 139.501 38.7682 139.501H118.166C118.166 139.501 116.275 123.796 112.227 118.387C108.179 112.978 91.9771 108.673 87.4068 105.694C82.8365 102.716 84.7108 85.7101 84.7108 85.7101C84.7108 85.7101 72.5405 89.4845 64.7094 85.4448Z" fill="white" stroke="#3A3A3A" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M55.3807 110.65C53.9086 111.369 52.291 112.157 50.6734 112.961C53.4892 121.52 50.8874 132.398 48.4824 139.493H104.712C103.822 137.61 103.223 135.607 102.924 133.553C102.008 127.126 102.616 117.42 103.044 112.413C101.144 111.557 99.1493 110.744 97.2151 109.974C94.3993 116.641 87.3556 129.59 75.5362 127.99C63.8194 126.424 57.7343 116.016 55.3807 110.65Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M51.2038 89.7673C51.2038 89.7673 52.8128 93.8156 58.8038 94.6201C64.7948 95.4246 61.8335 86.729 79.5327 86.1214C91.994 85.6934 79.6012 95.9638 82.3057 96.5115C85.0102 97.0593 91.7202 96.2376 95.5373 87.3281C99.3544 78.4186 100.125 55.7298 92.2936 48.4379C84.4625 41.1459 61.4826 41.4112 57.9993 47.8987C54.5159 54.3861 56.9123 65.7177 53.72 71.9312C50.5276 78.1448 49.0384 86.5236 51.2038 89.7673Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M58.8034 68.9441C58.8034 68.9441 56.6466 63.2697 55.8336 67.3265C55.0205 71.3833 55.8336 75.423 57.725 77.0491C59.6165 78.6752 60.6949 78.3928 60.6949 78.3928L58.8034 68.9441Z" fill="white" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M67.3034 40.0416C67.3034 40.0416 62.7417 41.1799 59.6948 49.5503C56.6479 57.9206 59.0272 79.9162 60.9272 82.2013C62.8272 84.4865 69.152 93.978 75.2372 94.3545C79.1143 94.5942 88.8368 85.6847 90.3603 81.1229C91.8837 76.5612 92.4143 56.0206 91.2675 51.0737C90.1206 46.1268 80.6206 34.7096 67.3034 40.0416Z" fill="white" stroke="#black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M71.1883 46.8285C71.1883 46.8285 64.4356 44.9799 61.7311 49.2592C59.0266 53.5385 60.3874 61.9516 56.3306 69.5088C52.2738 77.0661 54.713 88.6801 55.2522 90.3062C55.7914 91.9324 51.7432 94.0891 49.5864 85.9841C47.4297 77.8791 53.6347 70.048 54.1738 61.6777C54.713 53.3074 52.813 44.124 61.1919 39.2627C69.5708 34.4014 71.1883 37.3713 71.1883 37.3713V46.8285Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M104.403 59.5296C102.786 54.6683 103.051 45.7502 96.5722 38.4583C92.5068 33.888 84.7356 31.2348 78.3595 31.0808C77.3324 30.8668 76.2797 30.8069 75.2356 30.9011C64.9653 31.7569 58.495 37.1146 60.3864 41.1714C62.2779 45.2282 71.1874 46.8457 71.1874 46.8457L72.5396 47.3849C72.4968 46.9313 72.4797 46.4692 72.4883 46.007C73.9432 45.3309 76.4252 44.8858 79.0185 47.6502C83.5289 52.6998 86.028 59.2386 86.0365 66.017C85.7712 76.2873 90.8978 80.0617 90.8978 84.3838C90.8978 88.7059 83.3406 94.1063 81.7144 95.4586C79.9171 96.9564 88.5613 97.6924 89.1005 97.6582C97.8046 97.119 103.59 91.436 107.219 83.8531C111.49 74.9351 107.193 67.8913 104.403 59.5296Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M94.5964 91.813C94.1942 92.472 93.6721 93.054 93.0645 93.5247" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M71.1867 45.7418C71.1867 45.7418 64.7078 42.2327 70.1083 39.5368C75.5088 36.8408 85.5138 45.2026 87.9273 53.0337C90.3409 60.8648 88.4665 69.7829 91.9841 75.9879C93.6445 78.9406 95.8012 83.8361 95.6386 87.9699" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M66.3682 40.9406C66.1628 40.196 66.5736 39.1262 68.4821 37.6455C73.3434 33.8626 82.2615 35.7541 86.8489 40.6154C91.4363 45.4767 93.0625 51.4163 93.6016 58.4344C94.1408 65.4524 95.2192 69.8002 99.0278 74.0795C102.836 78.3588 104.967 82.989 101.185 88.3895" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M66.0504 45.7421C66.0504 45.7421 58.4932 42.507 62.8153 38.4502" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M56.5879 89.1851C57.1699 90.0409 57.9059 90.7855 58.7618 91.3846" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M54.9875 72.7529C54.9875 72.7529 53.0875 80.8237 55.3469 86.789" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M94.2012 43.2681C96.3323 46.8284 97.6845 50.7996 98.1724 54.9249C99.2507 64.6475 96.2809 67.8912 100.877 73.0178C102.88 75.1403 104.035 77.939 104.112 80.8574" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M90.8984 38.7236C90.8984 38.7236 91.2921 39.1259 91.8912 39.8876" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <path d="M73.3333 145.588C73.5344 145.588 73.7417 145.588 73.9556 145.588H82.0444C82.2583 145.588 82.4656 145.588 82.6667 145.588M73.3333 145.588C70.2585 145.591 68.6467 145.629 67.3974 146.255C66.2266 146.842 65.2747 147.777 64.6782 148.929C64 150.237 64 151.95 64 155.376V157.212C64 160.638 64 162.351 64.6782 163.66C65.2747 164.811 66.2266 165.747 67.3974 166.333C68.7284 167 70.4708 167 73.9556 167H82.0444C85.5292 167 87.2716 167 88.6026 166.333C89.7734 165.747 90.7253 164.811 91.3218 163.66C92 162.351 92 160.638 92 157.212V155.376C92 151.95 92 150.237 91.3218 148.929C90.7253 147.777 89.7734 146.842 88.6026 146.255C87.3533 145.629 85.7415 145.591 82.6667 145.588M73.3333 145.588V144.059C73.3333 142.369 74.7262 141 76.4444 141H79.5556C81.2738 141 82.6667 142.369 82.6667 144.059V145.588M82.6667 156.294C82.6667 158.828 80.5773 160.882 78 160.882C75.4227 160.882 73.3333 158.828 73.3333 156.294C73.3333 153.76 75.4227 151.706 78 151.706C80.5773 151.706 82.6667 153.76 82.6667 156.294Z" 
      stroke={colorsTheme.secondary.light} stroke-width="2" stroke-linecap="round" />
    </svg>
  );
} 