import { useForm } from "react-hook-form";
import { FormEvent, useCallback, useRef, useState } from "react";
import { useGlobal } from "../../../../hooks/global";
import { useApi } from "../../../../hooks/api";
import { GenericForm, IGenericFormRefProps } from "../../../../components/GenericForm";
import { IGroupProps } from "../../../../interfaces";
import { maskFunctions } from "../../../../services/maskServices";
import { Form } from "./style";
import Button from "../../../../components/Buttons/Button";
import { MyCircularProgress } from "../../../../components/MyCircularProgress";
import { useTheme } from "../../../../hooks/theme";

interface IStartProps {
  onSave: (reservation: any) => void;
  defaultReservationId?: string;
  defaultCheckInDate?: string;
}

export const Start: React.FC<IStartProps> = ({
  onSave,
  defaultReservationId,
  defaultCheckInDate,
}) => {
  const [loading, setLoading] = useState(false);

  const form_ref = useRef<IGenericFormRefProps>(null);

  const { notify } = useGlobal();
  const { api } = useApi();
  const { colorsTheme } = useTheme();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      reservation_stays_id: defaultReservationId ?? undefined,
      check_out_date: defaultCheckInDate
        ? maskFunctions.date.mask(
          defaultCheckInDate.split("-").reverse().join("/")
        )
        : undefined,
    } as any,
    mode: "all",
  });

  const _form = watch();

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "reservation_stays_id",
            label: "Código da reserva",
            type: "input",
            required: true,
          },
        ],
      ],
    },
  ];

  const onSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const form = form_ref.current?.getForm();
      const _form = {} as any;

      _form["reservation_stays_id"] = form["reservation_stays_id"];

      const { data: reservation } = await api.post("/check-out/start", _form);

      onSave(reservation);
    } catch (err: any) {
      const error = err.response ? err.response?.data : "SERVER ERROR";
      console.log(error);
      if (error.field)
        setError(error.field, { type: "manual", message: error.detail });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoading(false);
  }, [form_ref, _form]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form]
  );

  return (
    <>
      <div
        style={{
          display: loading ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
          flex: 1,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: 24,
            textAlign: "center",
            maxWidth: 300,
          }}
        >
          Preparado para iniciar seu check-out?
        </div>
        <div
          style={{
            fontSize: 16,
            textAlign: "center",
            maxWidth: 320,
            marginBottom: 20,
          }}
        >
          Informe o seu check-out para que nosso time inicie a limpeza de sua acomodação
        </div>

        <Form onSubmit={handleStopPropagation}>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              register={register}
              noTrigger
              containerStyle={{ width: "100%" }}
            />
          </div>
          <Button
            background={colorsTheme.secondary.light}
            color={"#292929"}
            type="submit"
            style={{ marginTop: 30 }}
          >
            iniciar checkout
          </Button>
        </Form>
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <MyCircularProgress/>
        </div>
      )}
    </>
  );
};
