import { useTheme } from "../../../../hooks/theme";

export const PhotoIcon = ({ size, color, ...props }: any) => {
  const { colorsTheme } = useTheme();

  return (
    <svg width="159" height="168" viewBox="0 0 159 168" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.0085 144.786C75.2171 144.786 75.4322 144.786 75.6541 144.786H84.0463C84.2682 144.786 84.4833 144.786 84.6919 144.786M75.0085 144.786C71.8184 144.788 70.1462 144.828 68.85 145.477C67.6353 146.086 66.6477 147.057 66.0288 148.251C65.3252 149.609 65.3252 151.386 65.3252 154.941V156.845C65.3252 160.4 65.3252 162.177 66.0288 163.535C66.6477 164.729 67.6353 165.7 68.85 166.309C70.2309 167 72.0386 167 75.6541 167H84.0463C87.6618 167 89.4695 167 90.8504 166.309C92.0651 165.7 93.0527 164.729 93.6716 163.535C94.3752 162.177 94.3752 160.4 94.3752 156.845V154.941C94.3752 151.386 94.3752 149.609 93.6716 148.251C93.0527 147.057 92.0651 146.086 90.8504 145.477C89.5542 144.828 87.882 144.788 84.6919 144.786M75.0085 144.786V143.199C75.0085 141.446 76.4537 140.025 78.2363 140.025H81.4641C83.2467 140.025 84.6919 141.446 84.6919 143.199V144.786M84.6919 155.893C84.6919 158.522 82.5242 160.653 79.8502 160.653C77.1762 160.653 75.0085 158.522 75.0085 155.893C75.0085 153.264 77.1762 151.133 79.8502 151.133C82.5242 151.133 84.6919 153.264 84.6919 155.893Z" stroke={colorsTheme.secondary.light} stroke-width="2" stroke-linecap="round" />
      <path d="M101.638 154.55H141.663C150.499 154.55 157.663 147.387 157.663 138.55V17C157.663 8.16344 150.499 1 141.663 1H17C8.16344 1 1 8.16346 1 17V138.55C1 147.387 8.16345 154.55 17 154.55H58.0625" stroke="#EBEBEB" stroke-width="2" stroke-dasharray="8 8" />
      <rect x="23.8252" y="23.8252" width="111.013" height="106.863" rx="8" fill={colorsTheme.secondary.light} opacity='0.6' />
      <path d="M117.657 49.7627H40.4611C39.8683 49.7627 39.3877 50.2431 39.3877 50.8357V104.433C39.3877 105.025 39.8683 105.506 40.4611 105.506H117.657C118.249 105.506 118.73 105.025 118.73 104.433V50.8357C118.73 50.2431 118.249 49.7627 117.657 49.7627Z" fill="#EBEBEB" />
      <path d="M115.592 53.1621H42.5249V105.506H115.592V53.1621Z" fill="#5B5B5B" />
      <path d="M112.873 63.1963H53.6792V105.505H112.873V63.1963Z" fill="#EBEBEB" />
      <path d="M110.686 64.9453H56.2461V68.4423H110.686V64.9453Z" fill="#AEAEAE" />
      <path d="M110.686 56.5068H56.2461V60.0038H110.686V56.5068Z" fill="#8D8D8D" />
      <path d="M110.686 70.3418H79.7808V73.8388H110.686V70.3418Z" fill="#AEAEAE" />
      <path d="M110.686 89.5225H79.7808V93.0194H110.686V89.5225Z" fill="#AEAEAE" />
      <path d="M110.686 95.2295H56.2461V98.7265H110.686V95.2295Z" fill="#AEAEAE" />
      <path d="M110.686 75.7383H79.7808V87.7379H110.686V75.7383Z" fill="#AEAEAE" />
      <path d="M77.476 70.3418H56.2461V93.0198H77.476V70.3418Z" fill="#AEAEAE" />
      <path d="M54.5696 81.7373H41.5259V105.505H54.5696V81.7373Z" fill="#EBEBEB" />
      <path d="M51.3272 83.8145H47.9575V103.199H51.3272V83.8145Z" fill="#AEAEAE" />
      <path d="M46.5664 100.304V85.7354H45.5252V100.304H46.5664Z" fill="#5B5B5B" />
      <path d="M49.7818 62.4915C51.7574 61.5731 52.6142 59.2278 51.6955 57.253C50.7767 55.2782 48.4304 54.4218 46.4547 55.3401C44.479 56.2585 43.6222 58.6038 44.541 60.5786C45.4597 62.5534 47.8061 63.4098 49.7818 62.4915Z" fill="#8D8D8D" />
      <path d="M51.7295 61.0864C50.9911 62.3101 49.6489 63.1302 48.118 63.1302C46.5872 63.1302 45.2081 62.2863 44.4767 61.0342L43.7935 61.7172C44.7134 63.1321 46.3081 64.0707 48.1185 64.0707C49.9289 64.0707 51.4868 63.1545 52.4113 61.7671L51.7299 61.086L51.7295 61.0864Z" fill="#8D8D8D" />
    </svg>
  );
} 