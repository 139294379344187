import Button from "../../../../../../components/Buttons/Button";
import { useState } from "react";
import { CgArrowLeft } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";
import { CreateOrEditGuest } from "./createOrEditGuest";
import { Guest, IReservation } from "../../../../interfaces";
import { fail, inactiveItens } from "../../../../../../theme";
import { AddGuest } from "../../../../../../components/SvgComponents/Icons/AddGuest";
import { TrashIcon } from "../../../../../../components/SvgComponents/Icons/TrashIcon";
import { PencilIcon } from "../../../../../../components/SvgComponents/Icons/PencilIcon";
import { ArrowRightIcon } from "../../../../../../components/SvgComponents/Icons/ArrowRightIcon";
import { useTheme } from "../../../../../../hooks/theme";

interface IGuestsStep {
  reservation: IReservation;
  onNext: () => void;
  goBack: () => void;
  guests: Guest[];
  setGuests: React.Dispatch<React.SetStateAction<Guest[]>>;
}

export const GuestsStep: React.FC<IGuestsStep> = ({
  onNext,
  reservation,
  guests,
  setGuests,
  goBack,
}) => {
  const [selectedGuest, setSelectedGuest] = useState<Guest | null>(null);
  const [creatingGuest, setCreatingGuest] = useState(false);
  const { colorsTheme } = useTheme();

  const handleConfirmDelete = (guest: Guest) => {
    const confirmDelete = window.confirm(
      `Deseja realmente excluir o hóspede '${guest.name}'?`
    );
    if (confirmDelete) {
      setGuests((oldGuests) => {
        const newGuests = oldGuests.filter(
          (oldGuest) =>
            oldGuest.cpf !== guest.cpf ||
            oldGuest.name !== guest.name ||
            oldGuest.phone !== guest.phone ||
            oldGuest.email !== guest.email
        );
        return newGuests;
      });
    }
  };

  const guestIsComplete = (guest: Guest) => {
    return (
      (guest.cpf || guest.passport) &&
      guest.name &&
      guest.type &&
      guest.email &&
      guest.phone
    );
  };

  if (selectedGuest?.type || creatingGuest)
    return (
      <CreateOrEditGuest
        defaultGuest={selectedGuest}
        onCancel={() => {
          setSelectedGuest(null);
          setCreatingGuest(false);
        }}
        onSave={(guest) => {
          setGuests((oldGuests) => {
            return [...oldGuests, guest];
          });
          setSelectedGuest(null);
          setCreatingGuest(false);
        }}
        editIndex={guests.findIndex(
          (guest) =>
            guest.cpf === selectedGuest?.cpf &&
            guest.name === selectedGuest?.name &&
            guest.phone === selectedGuest?.phone &&
            guest.email === selectedGuest?.email
        )}
        onEdit={(guest, editIndex) => {
          setGuests((oldGuests) => {
            oldGuests[editIndex] = guest;
            return oldGuests;
          });
          setSelectedGuest(null);
          setCreatingGuest(false);
        }}
      />
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Gerenciar Hóspedes</div>
            <div
              style={{ position: "absolute", right: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <div style={{ color: "#8D8D8D", fontSize: 12 }}>2 de 3</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {guests.map((guest, index) => (
            <>
              <div style={{ display: "flex", gap: 10 }}>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  {index === 0 && (
                    <div
                      style={{
                        color: "#5B5B5B",
                        fontSize: 14,
                      }}
                    >
                      Titular da Reserva
                    </div>
                  )}
                  {index !== 0 && (
                    <div
                      style={{
                        color: "#5B5B5B",
                        fontSize: 12,
                      }}
                    >
                      {index}º Acompanhante
                    </div>
                  )}
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: index === 0 ? 18 : 15,
                      marginBottom: 5,
                    }}
                  >
                    {guest?.name}
                  </div>
                  {!guestIsComplete(guest) && (
                    <div
                      style={{
                        color: fail,
                        fontSize: 12,
                        marginBottom: 5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FiAlertCircle
                        style={{ marginRight: 5 }}
                        size={16}
                        stroke={fail}
                      />
                      Hóspede com informações incompletas
                    </div>
                  )}
                  <div style={{ display: "flex", gap: 10 }}>
                    {index === 0 && (
                      <div
                        style={{
                          padding: "3px 20px",
                          borderRadius: 12,
                          fontSize: 10,
                          fontWeight: "bold",
                          background: colorsTheme.secondary.light,
                        }}
                      >
                        Titular
                      </div>
                    )}
                    {index !== 0 && (
                      <div
                        style={{
                          padding: "3px 20px",
                          borderRadius: 12,
                          fontSize: 10,
                          fontWeight: "bold",
                          background: "#EFEFEF",
                        }}
                      >
                        Acompanhante
                      </div>
                    )}
                    <div
                      style={{
                        padding: "3px 20px",
                        borderRadius: 12,
                        fontSize: 10,
                        fontWeight: "bold",
                        background:
                          guest.type === "adult" ? "#E6F0FF" : "#FFF7F7",
                      }}
                    >
                      {guest.type === "adult" ? "Adulto" : "Criança"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    marginTop: -10,
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    onClick={() => setSelectedGuest(guest)}
                    style={{ padding: 10, cursor: "pointer" }}
                  >
                    <PencilIcon />
                  </div>
                  {index !== 0 && (
                    <div
                      onClick={() => handleConfirmDelete(guest)}
                      style={{ padding: 10, cursor: "pointer" }}
                    >
                      <TrashIcon />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  borderTop: "1px solid #EFEFEF",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              />
            </>
          ))}
        </div>
        {reservation.total_guests > guests.length && (
          <div style={{ display: "flex" }}>
            <div
              onClick={() => setCreatingGuest(true)}
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <AddGuest />
              <div
                style={{
                  fontSize: 14,
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Adicionar novo hóspede
              </div>
            </div>
          </div>
        )}
      </div>
      <Button
        background={colorsTheme.secondary.light}
        color={"#292929"}
        style={{ marginTop: 30 }}
        onClick={onNext}
        disabled={
          guests.length !== reservation.total_guests ||
          guests.some((guest) => !guestIsComplete(guest))
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          Enviar documentos
          <ArrowRightIcon />
        </div>
      </Button>
    </div>
  );
};
