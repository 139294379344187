import { useTheme } from "../../../../hooks/theme";

export const GenericDocumentIcon = ({ size, color, background, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke={background}
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M14.5 18.5a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Zm0 0c3.585 0 6.5 2.906 6.5 6.492a.008.008 0 0 1-.008.008M14.5 18.5c-3.585 0-6.5 2.906-6.5 6.492 0 .004.004.008.008.008"
    />
    <path
      stroke="#3A3A3A"
      strokeLinecap="round"
      strokeWidth={2.5}
      d="M27 12h9m-9 6h9m-9 6h9M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6V8.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C5.04 2 6.16 2 8.4 2h27.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C42 5.04 42 6.16 42 8.4v19.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C38.96 34 37.84 34 35.6 34H8.4Z"
    />
  </svg>
);

export const GenericDocumentIcon2 = ({ size, color, background, ...props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={36}
      fill="none"
      {...props}
    >
      <path
        stroke="#AEAEAE"
        strokeLinecap="round"
        strokeWidth={2.5}
        d="M14.5 18.5a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Zm0 0c3.585 0 6.5 2.906 6.5 6.492a.008.008 0 0 1-.008.008M14.5 18.5c-3.585 0-6.5 2.906-6.5 6.492 0 .004.004.008.008.008"
      />
      <path
        stroke="#AEAEAE"
        strokeLinecap="round"
        strokeWidth={2.5}
        d="M27 12h9m-9 6h9m-9 6h9M8.4 34c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C2 30.96 2 29.84 2 27.6V8.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C5.04 2 6.16 2 8.4 2h27.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C42 5.04 42 6.16 42 8.4v19.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C38.96 34 37.84 34 35.6 34H8.4Z"
      />
    </svg>
  );
}
