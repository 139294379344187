import * as React from "react";
import { primary } from "../../../../theme";

export const UploadIcon = ({ size, color, background, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={52}
    height={44}
    fill="none"
    {...props}
  >
    <path
      fill={background}
      d="M27.5 23.177a1.5 1.5 0 1 0-3 0h3ZM24.5 42a1.5 1.5 0 0 0 3 0h-3Zm-6.75-15.189a1.5 1.5 0 0 0 2.1 2.142l-2.1-2.142Zm10.965-3.325 1.05-1.072-1.05 1.072Zm3.435 5.467a1.5 1.5 0 0 0 2.1-2.142l-2.1 2.142Zm-6.892-7.04.456 1.43-.456-1.43Zm1.484 0-.456 1.43.456-1.43Zm9.652-10.397.195 1.488-.195-1.488Zm-3.137-1.44 1.283-.778-1.283.777Zm8.952 22.507a1.5 1.5 0 0 0 1.182 2.758l-1.182-2.758Zm-33.856.644a1.5 1.5 0 1 0 1.694-2.476l-1.694 2.476ZM24.5 23.177V42h3V23.177h-3Zm-4.65 5.776 4.485-4.396-2.1-2.142-4.485 4.396 2.1 2.142Zm7.815-4.396 4.485 4.396 2.1-2.142-4.485-4.396-2.1 2.142Zm-3.33 0c.493-.483.799-.782 1.047-.989.234-.195.318-.221.332-.225l-.91-2.859c-.535.17-.963.464-1.341.779-.364.302-.77.703-1.228 1.151l2.1 2.143Zm5.43-2.142c-.457-.45-.864-.85-1.228-1.152-.378-.315-.806-.609-1.34-.779l-.91 2.859c.013.004.097.03.331.225.248.207.554.505 1.047.989l2.1-2.142Zm-4.051.928a.946.946 0 0 1 .572 0l.91-2.859a3.946 3.946 0 0 0-2.393 0l.91 2.859ZM3.5 18.47C3.5 10.23 10.322 3.5 18.8 3.5v-3C8.721.5.5 8.518.5 18.47h3ZM38 12.91c5.827 0 10.5 4.624 10.5 10.266h3c0-7.354-6.072-13.265-13.5-13.265v3Zm-1.411.093c.46-.06.932-.092 1.411-.092v-3c-.61 0-1.211.04-1.801.117l.39 2.975ZM18.8 3.5c5.614 0 10.512 2.956 13.174 7.352l2.566-1.554C31.347 4.025 25.488.5 18.8.5v3Zm29.7 19.677c0 4.19-2.572 7.814-6.29 9.406l1.18 2.758c4.758-2.038 8.11-6.706 8.11-12.165h-3ZM10.047 30.75C6.079 28.037 3.5 23.544 3.5 18.47h-3c0 6.12 3.116 11.517 7.853 14.756l1.694-2.476Zm26.152-20.722c-.699.092-1.36-.236-1.66-.73l-2.565 1.553c.97 1.604 2.846 2.383 4.615 2.152l-.39-2.975Z"
    />
  </svg>
);
