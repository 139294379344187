import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useApi } from "./api";

interface ColorProps {
    light: string;
    dark: string;
}

interface ThemeContextProps {
    colorsTheme: Record<ThemeColors, ColorProps>;
    colors: { [key: string]: string };
    logo: string;
    updateSecondaryColor: (light: string, dark: string) => void;
}

type ThemeColors = "primary" | "secondary" | "background" | "borderDisabled" | "activeItens" |
    "inactiveItens" | "activeSubItens" | "borderColors" | "disabledItems" | "inactiveSubItens" |
    "hover" | "separator" | "foreground" | "divider" | "tabActiveColor" | "tabInativeColor" |
    "shadowColor" | "rankingBackgroundOne" | "rankingBackgroundTwo";

const defaultColorsTheme: Record<ThemeColors, ColorProps> = {
    primary: { light: "#FFF", dark: "#242322" },
    secondary: { light: "#00FE79", dark: "#00FE79" },
    background: { light: "#f8f8f8", dark: "#1B191B" },
    borderDisabled: { light: "#e2e2e2", dark: "transparent" },
    activeItens: { light: "#158B4D", dark: "#158B4D" },
    inactiveItens: { light: "#3a3a3a", dark: "#8A8A8A" },
    activeSubItens: { light: "#158B4D", dark: "#158B4D" },
    borderColors: { light: "#CCCCCC", dark: "rgb(68 68 68)", },
    disabledItems: { light: "#F2F2F2", dark: "#545454" },
    inactiveSubItens: { light: "#3a3a3a", dark: "#707070" },
    hover: { light: "#e3eff8", dark: "#e9f3fa" },
    separator: { light: "#f3f3f3", dark: "#d7d7d7" },
    foreground: { light: "#fff", dark: "#fff" },
    divider: { light: "#dee2e6", dark: "#535353" },
    tabActiveColor: { light: "rgb(42 42 42)", dark: "#C4C4C4" },
    tabInativeColor: { light: "#C4C4C4", dark: "#707070" },
    shadowColor: { light: "rgba(0,0,0,0.1)", dark: "#121111" },
    rankingBackgroundOne: { light: "rgb(241, 242, 243)", dark: "#3a3a3a" },
    rankingBackgroundTwo: { light: "#FFF", dark: "#242322" },
};

const defaultColors: { [key: string]: string } = {
    tertiary: "#07C5A6",
    four: "#394F8F",
    fail: "#DE4444",
    success: "#4CAF50",
    info: "#FAAD14",
    gray: "#DBDBDB",
    white: "#FFF",
    backScan: "#BDE5F3"
};

const ThemeContext = createContext<ThemeContextProps>({
    colorsTheme: defaultColorsTheme,
    colors: defaultColors,
    updateSecondaryColor: () => { },
    logo: ''
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [colorsTheme, setColorsTheme] = useState<Record<ThemeColors, ColorProps>>(defaultColorsTheme);
    const [logo, setLogo] = useState<string>('');
    const [colors, setColors] = useState(defaultColors);

    const { api } = useApi();

    const updateSecondaryColor = (light: string, dark: string) => {
        setColorsTheme((prev) => ({
            ...prev,
            secondary: { light, dark },
        }));
    };

    const fetchVisualIdentity = useCallback(async () => {
        try {
            const { data } = await api.get("/visual-identity");

            if (data.COMPANY_COLOR) {
                updateSecondaryColor(data.COMPANY_COLOR, data.COMPANY_COLOR);
            }

            setLogo(data.COMPANY_LOGO);
        } catch (error) {
            console.error("Erro ao buscar cor secundária:", error);
        }
    }, []);

    useEffect(() => {
        fetchVisualIdentity();
    }, []);

    return (
        <ThemeContext.Provider value={{ colorsTheme, colors, updateSecondaryColor, logo }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("useTheme must be used within an ThemeProvider");
    }

    return context;
} 
