import Button from "../../../../../../components/Buttons/Button";
import { IReservation } from "../../../../interfaces";
import { NightIcon } from "../../../../../../components/SvgComponents/Icons/NightIcon";
import { ArrowRightIcon } from "../../../../../../components/SvgComponents/Icons/ArrowRightIcon";
import { CgArrowLeft } from "react-icons/cg";
import { inactiveItens } from "../../../../../../theme";
import { useTheme } from "../../../../../../hooks/theme";

interface IAccommodationStepProps {
  reservation: IReservation;
  onNext: () => void;
  goBack: () => void;
}

const formatAddress = (address: IReservation["accommodation"]["address"]) => {
  return `${address.street}, ${address.streetNumber}, ${address.region}, ${address.city}, ${address.state}, CEP ${address.zip}`;
};

const getNightsAndDays = (checkIn: string, checkOut: string) => {
  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);

  const diffTime = Math.abs(checkOutDate.getTime() - checkInDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const nights = diffDays;
  const days = diffDays + 1;

  return `${nights} ${nights > 1 ? "noites" : "noite"}, ${days} ${days > 1 ? "dias" : "dia"}`;
};

export const ReservationStep: React.FC<IAccommodationStepProps> = ({
  reservation,
  onNext,
  goBack,
}) => {
  const { colorsTheme } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        flex: 1,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", gap: 10, flex: 1 }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
              position: "relative",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div
              style={{ position: "absolute", left: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <CgArrowLeft color={inactiveItens.light} size={20} />
            </div>
            <div>Detalhes da Reserva</div>
            <div
              style={{ position: "absolute", right: 0 }}
              className="pointer"
              onClick={() => goBack()}
            >
              <div style={{ color: "#8D8D8D", fontSize: 12 }}>1 de 3</div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontSize: 12, color: "#5B5B5B" }}>
            Código da Reserva
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#292929",
              textTransform: "uppercase",
            }}
          >
            {reservation.id}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <div style={{ fontWeight: "bold", fontSize: 14 }}>
            {reservation.accommodation.title}
          </div>
          <div
            style={{
              fontSize: 12,
              color: "#5B5B5B",
            }}
          >
            {formatAddress(reservation.accommodation.address)}
          </div>
        </div>
        <div style={{ borderTop: "1px solid #EFEFEF", marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div style={{ fontSize: 12, fontWeight: "bold" }}>
              Data de entrada
            </div>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              {reservation.check_in_date_formated}
            </div>
            <div style={{ fontSize: 12, color: "#5B5B5B" }}>
              Check-in a partir das{" "}
              {reservation.check_in_time.replace(":", "h")}
            </div>
          </div>
          <div style={{ borderRight: "1px solid #EFEFEF" }} />
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div style={{ fontSize: 12, fontWeight: "bold" }}>
              Data de saída
            </div>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              {reservation.check_out_date_formated}
            </div>
            <div style={{ fontSize: 12, color: "#5B5B5B" }}>
              Check-out até às {reservation.check_out_time.replace(":", "h")}
            </div>
          </div>
        </div>
        <div style={{ borderTop: "1px solid #EFEFEF" }} />
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <NightIcon />
          <div style={{ fontWeight: "bold" }}>
            {getNightsAndDays(
              reservation.check_in_date as any,
              reservation.check_out_date as any
            )}
          </div>
        </div>
        <div style={{ borderTop: "1px solid #EFEFEF" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          <div style={{ fontWeight: "bold" }}>Hóspedes</div>
          <div style={{ color: "#5B5B5B" }}>
            {reservation.total_guests}{" "}
            {reservation.total_guests > 1 ? "hóspedes" : "hóspede"}
          </div>
        </div>
      </div>
      <Button
        background={colorsTheme.secondary.light}
        color={"#292929"}
        style={{ marginTop: 30 }}
        onClick={onNext}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
            padding: "0px 10px",
          }}
        >
          Gerenciar Hóspedes
          <ArrowRightIcon />
        </div>
      </Button>
    </div>
  );
};
