import React, { useCallback } from "react";
import { Tooltip } from "../../Tooltip";
import { useGlobal } from "../../../hooks/global";
import { ContainerIcon, ContainerTooltip } from "./style";
import { useTheme } from "../../../hooks/theme";

interface ISwitchOptionProps {
    label: string
    value: string
    icon: any
}

interface ISwitchInputProps {
    options: [ISwitchOptionProps, ISwitchOptionProps]
    value: string
    onChange: (value: any) => void
    inputValue: any
    disabled?: boolean
}

export const SwitchInput: React.FC<ISwitchInputProps> = (props) => {
    const { options, value, inputValue, onChange, disabled } = props

    const { theme } = useGlobal()
    const { colorsTheme } = useTheme();

    const handleChange = useCallback(() => {
        if (disabled) return
        const newValue = options.find((option) => option?.value !== value)?.value ?? null
        onChange({ type: newValue, value: inputValue })
    }, [options, inputValue, value, disabled])

    return (
        <ContainerTooltip disabled={disabled}>
            <Tooltip
                title={"Alternar para " + (options.find((option) => option.value !== value)?.label ?? "")}
                placement="top"
                disableFocusListener={disabled}
                disableHoverListener={disabled}
                disableTouchListener
                interactive={false}
                style={{ margin: 0, padding: 0 }}
                children={(
                    <ContainerIcon
                        disabled={disabled}
                        onClick={() => handleChange()}
                        theme={theme}
                        background={colorsTheme.secondary.light}
                        children={(
                            <div>
                                {options.find((option) => option.value === value)?.icon}
                            </div>
                        )}
                    />
                )}
            />
        </ContainerTooltip>
    )
}